<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrChip from '@/components/SkrChip.vue'

defineProps<{
  loading: boolean
}>()

defineEmits<{
  (event: 'close'): void
}>()

const { t } = useI18n()

const businessStore = useBusinessStore()

const { businessRepository } = useApi()

const title = computed(() => {
  return businessStore.isTrialExpired
    ? t('dialog.business_eot_member.title')
    : t('dialog.business_locked_failed_payments_member.title')
})

const { data: admins } = useLazyAsyncData(
  'admins',
  async () => {
    if (!businessStore.id) return []
    return await businessRepository.getAdmins(businessStore.id)
  },
  {
    default: () => [],
  }
)
</script>

<template>
  <base-dialog :title="title" persistent max-width="796" data-cy="member_trial_end_dialog">
    <v-row class="justify-center align-center py-10">
      <v-col>
        <div class="pb-4 | dialog-business-eot-member__subtitle">
          {{ t('dialog.business_eot_member.subtitle') }}
        </div>
        <p class="pb-4 | dialog-business-eot-member__text">
          {{ t('dialog.business_eot_member.message') }}
        </p>
        <div v-for="(admin, index) in admins" :key="index" class="pa-4 mb-2 | dialog-business-eot-member__admin">
          <v-row align="center">
            <v-col cols="9">
              <div class="text-left | dialog-business-eot-member__admin--name">
                <strong>{{ admin.firstName }}, {{ admin.lastName }}</strong>
              </div>
              <div class="text-left | dialog-business-eot-member__admin--email">
                {{ admin.email }}
              </div>
            </v-col>
            <v-col cols="3" class="text-right">
              <skr-chip color="success">{{ t('global.admin') }}</skr-chip>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-if="$vuetify.display.mdAndUp">
        <responsive-image source="dialog-company-building" :width="261" :height="272" />
      </v-col>
    </v-row>
    <template #actions>
      <v-spacer />
    </template>
  </base-dialog>
</template>

<style lang="sass">
$ns: dialog-business-eot-member

.#{$ns}
  &__subtitle
    font-size: 1.5rem
    line-height: 1
    font-weight: bold
    color: $c-skribbleu
  &__text
    line-height: 26px

  &__admin
    background: rgba(#C4C4C4, 0.11)
    border-radius: 7px
</style>
